<template>
	<div class="shop_zong">
		<div class="trans_left">
			<div class="poo">店铺管理</div>
			<div class="xiala_item">
				<div class="xiala_box" @click="changeTab()" v-for="(item, index) in list" :key="index">
					<router-link class="xiala_box" :class="{ active: act == item.path }" :to="item.path">{{ item.name }}
					</router-link>
				</div>
			</div>
		</div>
		<div class="right1">
			<router-view></router-view>
		</div>
	</div>
</template>

<script>
	export default {
		components: {

		},
		data() {
			return {
				list: [{
						name: "店铺信息",
						path: "/shop/shop_information",
					},
					{
						name: "营销活动",
						path: "/shop/Market_activity",
					},
					{
						name: "账号信息",
						path: "/shop/Account",
					},
					{
						name: "店铺装修",
						path: "/shop/Renovation",
					},
				],
				act: '/shop/shop_information',
				Jump_page: "",
			};
		},
		updated() {
			this.changeTab();
		},
		mounted() {
			// console.log(this.$route.path)
			this.changeTab();
		},
		methods: {
			changeTab() {
				this.act = this.$route.path;
			},
		},

	};
</script>

<style scoped>
	@import "./shop.css";
</style>
